import { Link, useNavigate } from "react-router-dom";
import { markets } from "../data/market_data";

export default function Home() {
  const navigate = useNavigate();
  return (
    <div
      className="my-16 max-w-screen-xl mx-auto"
    >
      <section class="py-8 px-4 mx-4">
        <div class="text-center lg:py-16 lg:px-12">
          <a
            href="https://sokokijiji.co.ke"
            target="_blank"
            class="inline-flex justify-between items-center py-1 px-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full"
            role="alert"
          >
            <span class="text-sm font-medium">Powered by Sokokijiji</span>
            <svg
              class="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
            We connect you to the right Market
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-40">
            Digisoko is the just the right marketplace contact directory for
            you. Get connected to the right market for your groceries. Meet your
            favourite vendors and get the best deals.
          </p>
        </div>
      </section>

      <section class="bg-white max-w-screen-xl mx-4">
        <div class="grid grid-cols-1 gap-6 lg:grid-cols-4 lg:gap-8">
          {markets.map((market) => (
            <article
              onClick={() =>
                navigate(`/market/${market.slug}`, { state: market })
              }
              className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg cursor-pointer"
              key={market.id}
            >
              <img
                alt=""
                src={market.image}
                className="absolute inset-0 h-full w-full object-cover"
              />

              <div className="relative bg-gradient-to-t from-gray-900/80 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
                <div className="p-4 sm:p-6">
                  <time
                    datetime="2022-10-10"
                    className="block text-xs text-white/90"
                  >
                    {market.location}
                  </time>

                    <h3 className="mt-0.5 text-xl text-white font-semibold">
                      {market.name}
                    </h3>

                  <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                    {market.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
}
