import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function MarketDetails() {
  const { state } = useLocation();
  console.log(state);
  return (
    <div className="bg-white my-16 max-w-screen-xl mx-auto">
      <section class="py-8 px-4">
        <Link
          to="/"
          href="#"
          class="inline-flex justify-between items-center py-1 px-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full"
          role="alert"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="text-sm font-medium">Go Back</span>
        </Link>
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">
          {state.name}
        </h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl">
          Contacts of the vendors in {state.name}
        </p>

        <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-6">
          {state.contacts.map((contact, index) => (
            <article
              className="rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition hover:shadow-lg sm:p-6"
              key={index}
            >
              <img
                src={`https://ui-avatars.com/api/?name=${contact.name}&background=random&color=fff`}
                alt="phone"
                className="h-8 w-8 rounded-full mb-2"
              />

              <h3 className="mt-0.5 text-lg font-medium text-gray-900">
                {contact.name}
              </h3>

              <p className="line-clamp-3 text-sm/relaxed text-gray-500">
                {contact.phone}
              </p>

              <a
                href={`tel:${contact.phone}`}
                className="group mt-2 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
              >
                Contact
                <span
                  aria-hidden="true"
                  className="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                >
                  &rarr;
                </span>
              </a>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
}
