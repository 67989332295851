export const markets = [
  {
    id: 1,
    slug: "kangemi",
    name: "Kangemi Market",
    image: "/images/kangemi.jpg",
    location: "Kangemi, Nairobi",
    description:
      "Kangemi Market is a market located in the Kangemi area of Nairobi. It is a market that is known for its fresh produce and is a great place to shop for fruits and vegetables. The market is open daily and is a great place to get fresh produce at affordable prices.",
    contacts: [
      { name: "Felista Waithira", phone: "0792475496" },
      { name: "Elizabeth Gathoni", phone: "0788202177" },
      { name: "Teresia Njeri", phone: "0714025204" },
      { name: "Lydia Wambui", phone: "0715350149" },
      { name: "Karen Nduta", phone: "0728794039" },
      { name: "Mary Njonjo", phone: "0704050086" },
      { name: "Anastasia Wambui", phone: "0701088050" },
      { name: "Prisca Muthoni", phone: "0759656078" },
      { name: "Joyce Wambui", phone: "0716313809" },
      { name: "J Kabage Mwangi", phone: "0728731331" },
      { name: "Evans Koech", phone: "0748296465" },
      { name: "Humphrey Muchangi", phone: "0708364622" },
      { name: "John Mwangi", phone: "0723732528" },
      { name: "Vincent Mageria", phone: "0724167479" },
      { name: "Elizabeth Gathoni", phone: "0111899954" },
      { name: "Karen Wambui", phone: "0724947313" },
      { name: "Abednego Mweu", phone: "0702642218" },
      { name: "Anastacia Tungah", phone: "0729872556" },
      { name: "Catherine Daudi", phone: "0712296340" },
      { name: "Mary Muthoni", phone: "0720457320" },
      { name: "Sarah Wanjiru", phone: "0743512771" },
      { name: "Nancy Nduta", phone: "0727750922" },
      { name: "Nancy Njeri", phone: "0719127152" },
      { name: "Ann Mbete", phone: "0705960677" },
      { name: "Phylis Wanjiru", phone: "0792045661" },
      { name: "Diana Monica", phone: "0700769697" },
      { name: "Mary Kisinga", phone: "0795524834" },
      { name: "Lucy Nyambura", phone: "0720267050" },
      { name: "Priscah Muthoni", phone: "0759656078" },
      { name: "Beth Wangari", phone: "0713227326" },
      { name: "Anastacia Wambui", phone: "0701088050" },
      { name: "Lydia Wambui", phone: "074350149" },
      { name: "JosPhine Kariuki", phone: "0797285336" },
      { name: "Maureen Wachira", phone: "0726356017" },
      { name: "Jackline Amaya", phone: "0713087584" },
      { name: "Caroline Omete", phone: "0726494502" },
      { name: "Catherine Mideva", phone: "0717324519" },
      { name: "Isabella Mayomi", phone: "0725304393" },
      { name: "Mizi Muhambe", phone: "0727370092" },
      { name: "Edith Lintari", phone: "0707445816" },
      { name: "Vivian Imjorogo", phone: "0702524257" },
      { name: "Diana Nguka", phone: "0723791346" },
      { name: "Lucy Sharon", phone: "0723586454" },
      { name: "Beatrice Wanjiku", phone: "0712308496" },
      { name: "Mary Njoki", phone: "0708859935" },
      { name: "Ann Ngugi", phone: "0725953513" },
      { name: "Pascaline Nyang'oso", phone: "0724975509" },
      { name: "Joyce Njeri", phone: "0719796363" },
      { name: "Edith Nyanchama", phone: "0725905168" },
      { name: "Purity Moraa", phone: "0701036085" },
      { name: "Beatrice Muguma", phone: "0705130622" },
      { name: "Dorcas Wairimu", phone: "0768615817" },
      { name: "Grace Wangechi", phone: "0727144145" },
    ],
  },
  {
    id: 2,
    slug: "kawangware",
    name: "Kawangware Market",
    image: "/images/kawangware.jpg",
    location: "Kawangware, Nairobi",
    description:
      "Kawangware Market is a market located in the Kawangware area of Nairobi. It is a market that is known for its fresh produce and is a great place to shop for fruits and vegetables. The market is open daily and is a great place to get fresh produce at affordable prices.",
    contacts: [
      { name: "Zacharia Sagini", phone: "0729107221" },
      { name: "Lydia Kemunto", phone: "0716492164" },
      { name: "Daniel Nyangaresi", phone: "0713961986" },
      { name: "Rose Kemuma", phone: "0727319470" },
      { name: "Jane Mutuota", phone: "0706137322" },
      { name: "Agnes Maugo", phone: "0713973334" },
      { name: "Alice Njoroge", phone: "0716833412" },
      { name: "Alice Kanithi", phone: "0715405444" },
      { name: "Hannah Wambui", phone: "0725247297" },
      { name: "Lucy Wangui", phone: "0721948470" },
      { name: "Stacy Mathu", phone: "0743230250" },
      { name: "Yvonne Gitari", phone: "0746941271" },
      { name: "Alice Ngendo", phone: "0716163511" },
      { name: "Alice Wanjiru", phone: "0722494471" },
      { name: "Jane Muthoni", phone: "0706837322" },
      { name: "Joyce Wambui", phone: "0729316842" },
      { name: "Harriet Wambui", phone: "0720247792" },
      { name: "Sarah Wambui", phone: "0712341494" },
      { name: "Margaret Wambui", phone: "0721768377" },
      { name: "Janet Wanjiru", phone: "0719204267" },
      { name: "Virginia Muthoni", phone: "0702737564" },
      { name: "Agnes Kingui", phone: "0713913130" },
    ],
  },
  {
    id: 3,
    slug: "kiamaiko",
    name: "Kiamaiko Market",
    image: "/images/kiamaiko.jpg",
    location: "Kiamaiko, Nairobi",
    description:
      "Kiamaiko Market is a market located in the Kiamaiko area of Nairobi. It is a market that is known for its fresh produce and is a great place to shop for fruits and vegetables. The market is open daily and is a great place to get fresh produce at affordable prices.",
    contacts: [
      { name: "Mamo Mohammed", phone: "0722537079" },
      { name: "Mohammed Hassan", phone: "0796267315" },
      { name: "Jefferson Nyaboga", phone: "0715053606" },
      { name: "Livingstone Ayioka", phone: "0715044108" },
      { name: "Abdirizak Abdullahi", phone: "0742257500" },
      { name: "Mike Opino", phone: "0769651629" },
      { name: "Sylvester Ondoro", phone: "0701576548" },
      { name: "Samuel Mwaura", phone: "0759265161" },
      { name: "Halima Mohammed", phone: "0745387507" },
      { name: "Elizabeth Wayua", phone: "0728969700" },
      { name: "Lindah Akoth", phone: "0716037007" },
      { name: "Millicent Akinyi", phone: "0792823635" },
      { name: "Vivian Adhiambo", phone: "0715029585" },
      { name: "Martha Achieng", phone: "0724870298" },
      { name: "Triza Anyango", phone: "0723850201" },
      { name: "Joyce Mboya", phone: "0715792583" },
      { name: "Elizabeth Akinyi", phone: "0721828417" },
      { name: "Rose Holo", phone: "0721343738" },
    ],
  },
  {
    id: 4,
    slug: "toi",
    name: "Toi Market",
    image: "/images/toi.jpg",
    location: "Toi Market, Nairobi",
    description:
      "Toi Market is a market located in the Toi area of Nairobi. It is a market that is known for its fresh produce and is a great place to shop for fruits and vegetables. The market is open daily and is a great place to get fresh produce at affordable prices.",
    contacts: [
      { name: "Caroline Wesonga", phone: "0721169580" },
      { name: "Lilian Swala", phone: "0716332045" },
      { name: "Lilian Kwamboka", phone: "070110234203" },
      { name: "Linet Kavochi", phone: "0700746866" },
      { name: "Mary Saka", phone: "0728756821" },
      { name: "Benta Oginga", phone: "0799244339" },
      { name: "Mercy Ocheck", phone: "0714939545" },
      { name: "Monica Khisa", phone: "0729005861" },
      { name: "Rosemary Achieng", phone: "0718157203" },
      { name: "Eunice Musera", phone: "0716199161" },
      { name: "Florence Onyango", phone: "0728130667" },
      { name: "Sharlin Ochieng", phone: "0793990916" },
      { name: "Lavender Mbori", phone: "0745138856" },
      { name: "Imelda Oguna", phone: "0794900039" },
      { name: "Easther Afwata", phone: "0727202071" },
      { name: "Juliana Mukukis", phone: "0718529105" },
      { name: "Rita Wanja", phone: "0746668269" },
      { name: "Phoebe Awendo", phone: "0720831883" },
      { name: "Belinda Nekesa", phone: "0795181218" },
      { name: "Jane Aluoch", phone: "0713384576" },
      { name: "Michael Oduori", phone: "0715649334" },
      { name: "Anderson Ndombi", phone: "0726179828" },
      { name: "Doreen Maina", phone: "0790730015" },
      { name: "David Odongo", phone: "0708951036" },
      { name: "Joseph Odhiambo", phone: "0795879291" },
      { name: "Peter Okila", phone: "0798491079" },
      { name: "Dennis Otieno", phone: "0748773328" },
    ],
  },
  {
    id: 5,
    slug: "gikomba",
    name: "Gikomba Market",
    image: "/images/gikomba.jpg",
    location: "Gikomba Market, Nairobi",
    description:
      "Gikomba Market is a market located in the Gikomba area of Nairobi. It is a market that is known for its fresh produce and is a great place to shop for fruits and vegetables. The market is open daily and is a great place to get fresh produce at affordable prices.",
    contacts: [
      { name: "Mary Akello", phone: "0723350230" },
      { name: "Angella Akoth", phone: "0720007250" },
      { name: "Margret Akinyi", phone: "0720329917" },
      { name: "Naomi Odhiambo", phone: "0720497979" },
      { name: "Everlyne Otiu", phone: "0723408910" },
      { name: "Lillian Adenyi", phone: "0720003234" },
      { name: "Evelyn Atieno", phone: "0727457653" },
      { name: "Celestine Akoth", phone: "0724604349" },
      { name: "Florence Akinyi", phone: "0791947383" },
      { name: "Pamela Muombi", phone: "0720482291" },
      { name: "Jane Odallo", phone: "0721243573" },
      { name: "Beatrice Adhiambo", phone: "0721596360" },
      { name: "Grace Akinyi", phone: "0717672033" },
      { name: "Mary Waruinu", phone: "0725551921" },
      { name: "Milka Mwihaki", phone: "0723139899" },
      { name: "Josephine Njuki", phone: "0713836090" },
      { name: "Nancy Kamau", phone: "0721606034" },
      { name: "Hanna Mumbi", phone: "0728598750" },
      { name: "Teresia Njeri", phone: "0702311471" },
      { name: "Margret Muriithi", phone: "0712277950" },
      { name: "Rose Wanjiku", phone: "0721347991" },
      { name: "Rose Wangeci", phone: "0768060936" },
      { name: "Jane Mutagia", phone: "0712824785" },
      { name: "Lydia Wanjiku", phone: "0723758971" },
      { name: "Ann Wanjiru", phone: "0728668940" },
      { name: "Lucy Murage", phone: "0724245162" },
      { name: "Caroline Akinyi", phone: "0700784974" },
      { name: "Jennifer", phone: "0724569890" },
    ],
  },
];
